export function unEscapeHtml(htmlStr) {
    if (htmlStr != null) {
        if (htmlStr.match(/&lt;/g))
            htmlStr = htmlStr.replace(/&lt;/g, "<span>&#60;</span>");

        if (htmlStr.match(/&gt;/g))
            htmlStr = htmlStr.replace(/&gt;/g, "<span>&#62;</span>");

        if (htmlStr.match(/&quot;/g))
            htmlStr = htmlStr.replace(/&quot;/g, "\"");

        if (htmlStr.match(/&#39;/g))
            htmlStr = htmlStr.replace(/&#39;/g, "\'");

        if (htmlStr.match(/&amp;/g))
            htmlStr = htmlStr.replace(/&amp;/g, "&");


        return htmlStr;
    } else return ''
}


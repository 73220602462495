import * as React from 'react';
import { Fragment } from "react";
import { Helmet } from 'react-helmet-async'
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate, useLocation } from "react-router-dom"
import { RiepilogoQuizSvolti } from './riepilogoQuizSvolti';
import { Sezione } from '../components/impostazioniQuestionario/sezione';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox, FormControlLabel } from '@mui/material';
import Footer from '../components/shared/footer';

export default function ImpostazioniQuestionarioRifatto(props) {

    let abortController = new AbortController();
    let signal = abortController.signal;
    const navigate = useNavigate()
    const location = useLocation();

    const [tipoTest, setTipoTest] = useState('Sim');
    const [riepilogoDialogOpen, setRiepilogoDialogOpen] = useState(false);
    const [questionarioSelezionatoID, setQuestionarioSelezionatoID] = useState(props.idQuestionario);
    const [questionario, setQuestionario] = useState({});
    const [sezioni, setSezioni] = useState([]);

    const [isbnVolume, setIsbnVolume] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [percentualiQuiz, setPercentualiQuiz] = useState([]);
    const [sezioniImpostazioni, setSezioniImpostazioni] = useState([]);
    const [idUtente, setIdUtente] = useState(99);
    const [numeroQuiz, setNumeroQuiz] = useState(0);
    const [quizTotaliSequenziali, setQuizTotaliSequenziali] = useState(1);
    const [quizSequenzialiSelezionati, setQuizSequenzialiSelezionati] = useState(1);
    const [quizTotaliSequenzialiSelezionati, setQuizTotaliSequenzialiSelezionati] = useState(1);
    const [quizTotaliDisponibili, setQuizTotaliDisponibili] = useState(0);
    const [isGratuito, setIsGratuito] = useState(false);
    const [notLoggedDialogOpen, setNotLoggedDialogOpen] = useState(false);
    const [questionarioNotFound, setQuestionarioNotFound] = useState(false);
    const [questionarioIsLoaded, setQuestionarioIsLoaded] = useState(true);
    const [descrizione, setDescrizione] = useState("Edizioni Simone");
    const [pageTitle, setPageTitle] = useState('Questionario');
    const [permalinkUrl, setPermalinkUrl] = useState(process.env.REACT_APP_PUBLIC_URL)

    const [descrizioneQuestionario, setDescrizioneQuestionario] = useState("");
    const [soloQuizSbagliati, setSoloQuizSbagliati] = useState(false);

    useEffect(() => {
        if (location.state != null) {
            if (location.state.idQuestionario != null)
                setQuestionarioSelezionatoID(location.state.idQuestionario)
            if (location.state.idUtente != null)
                setIdUtente(location.state.idUtente)
            if (location.state.idQuestionario != null)
                getQuestionarioByID(location.state.idQuestionario, true);
            if (location.state.isbn != null)
                setIsbnVolume(location.state.isbn)
        } else permalinkCheck();
    }, []);

    async function permalinkCheck() {
        let currentUrl = window.location.href;
        let permalink = currentUrl.split("sim/")[1];
        if (permalink != "" && permalink != undefined && permalink != null) {
            setPermalinkUrl(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sim/" + permalink)
            window.history.pushState({}, 'Simulatore', process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/questionario/" + permalink);
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'permalink': permalink,
                })
            };
            let url = process.env.REACT_APP_FETCH_URL + 'questionario/permalink/check';
            try {
                let res = await fetch(url, requestOptions);
                if (res.status === 200) {
                    res.json().then(
                        result => {
                            if (result.success === true)
                                permalinkResolver(permalink);
                        }
                    )
                }
                else {
                    if (res.status === 403) {
                        window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                    } else {
                        navigate("/home")
                    }
                }


            } catch (error) {
            }
        } else {
            navigate("/home")
        }
    }
    async function permalinkResolver(permalink) {

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'permalink': permalink,
            })
        };
        let url = process.env.REACT_APP_FETCH_URL + 'questionario/permalink/resolve';
        try {
            let res = await fetch(url, requestOptions);
            if (res.status === 200) {
                res.json().then(
                    result => {
                        setQuestionarioSelezionatoID(result.data.idQuestionario);
                        setIsGratuito(true);
                        getQuestionarioGratuito(result.data.idQuestionario, true);
                    }
                )
            }
            else {
                if (res.status === 403) {
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                } else {
                    navigate("/home")
                }
            }


        } catch (error) {
        }

    }

    async function getQuestionarioGratuito(idQuestionario, firstGet) {
        let url = process.env.REACT_APP_FETCH_URL + "questionario/getGratuito/" + idQuestionario;
        try {
            let res = await fetch(url, { signal: signal });
            if (res.status === 200) {
                res.json().then(
                    result => {
                        if (result.success) {
                            // let idUtenteTmp = undefined

                            // tolto a fini di test
                            // if (location.state != null)
                            //     idUtenteTmp = location.state.idUtente;
                            setQuestionario({
                                nome: result.data.nomeQuestionario,
                                tipologia: result.data.tipologia,
                                gratuito: result.data.gratuito,
                                idVolume: result.data.volumeAssociato.idVolume,
                                permalink: result.data.permalink,
                                sottotitolo: result.data.sottotitolo,
                                sogliaSufficienza: result.data.sogliaSufficienza

                            })
                            let sezioniTmp = result.data.sezione;
                            setPageTitle(result.data.nomeQuestionario);
                            setSezioni(sezioniTmp);
                            if (result.data.descrizionePubblica !== "")
                                setDescrizione(result.data.descrizionePubblica);
                            else {
                                setDescrizione("Completa il simulatore " + result.data.nomeQuestionario);
                            }

                            if (result?.data?.introduzione) {
                                setDescrizioneQuestionario(result?.data?.introduzione ?? "");
                            }
                            let tmpSezioniImpostazioni = Array(sezioniTmp.length)
                            let quizDisponibiliTmp = 0;
                            let quizSequenzialiTotali = 0;
                            for (let i = 0; i < tmpSezioniImpostazioni.length; i++) {
                                //Appena creo l'array al numero di quiz selezionati assegno il valore max
                                if (sezioniTmp[i].quizDisponibili >= sezioniTmp[i].numQuiz)
                                    quizDisponibiliTmp += parseInt(sezioniTmp[i].numQuiz);
                                else quizDisponibiliTmp += parseInt(sezioniTmp[i].quizDisponibili);

                                let quizSelezionatiTmp = parseInt(sezioniTmp[i].numQuiz);

                                if (quizSelezionatiTmp > parseInt(sezioniTmp[i].quizDisponibili))
                                    quizSelezionatiTmp = parseInt(sezioniTmp[i].quizDisponibili)
                                tmpSezioniImpostazioni[i] = {
                                    "quizSelezionati": quizSelezionatiTmp,
                                    "quizDisponibili": parseInt(sezioniTmp[i].quizDisponibili),
                                    "idParte": parseInt(sezioniTmp[i].idParte),
                                    "tempMin": sezioniTmp[i].tempMin,
                                    "tempMax": sezioniTmp[i].tempMax,
                                    "offset": 0,
                                    "idVolume": sezioniTmp[i].idVolume,
                                    "puntiCorretta": sezioniTmp[i].puntiCorretta,
                                    "puntiErrata": sezioniTmp[i].puntiErrata,
                                    "puntiOmessa": sezioniTmp[i].puntiOmessa
                                }
                                quizSequenzialiTotali += parseInt(sezioniTmp[i].quizDisponibili)
                            }


                            if (firstGet) //Solamente al primo get imposto il numero di quiz
                                setNumeroQuiz(quizDisponibiliTmp);

                            setSezioniImpostazioni(tmpSezioniImpostazioni);
                            setQuizTotaliDisponibili(quizDisponibiliTmp);
                            setQuizTotaliSequenziali(quizSequenzialiTotali);
                            setQuizTotaliSequenzialiSelezionati(quizSequenzialiTotali);
                            // getQuizSvolti(idQuestionario, tmpSezioniImpostazioni);
                            setIsLoading(false);
                        }
                        else {
                            setQuestionarioNotFound(true);
                        }
                    }
                )
            } else if (res.status === 404 || res.status === 500) {
                setQuestionarioNotFound(true);
            } else if (res.status === 403) {
                window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
            } else if (res.status === 401) {
                navigate("/")
                //getQuestionarioByID(idQuestionario, true);
            }
        } catch (error) { }
    }

    async function getQuestionarioByID(idQuestionario, firstGet) {
        let url = process.env.REACT_APP_FETCH_URL + "questionario/getById/" + idQuestionario;
        try {
            let res = await fetch(url, { signal: signal });
            if (res.status === 200) {
                res.json().then(
                    result => {
                        if (result.success) {
                            // tolto a fini di test
                            let idUtenteTmp = undefined
                            if (location.state != null)
                                idUtenteTmp = location.state.idUtente;
                            if (localStorage.getItem("id") != null) {
                                idUtenteTmp = localStorage.getItem("id")
                            }
                            if (process.env.REACT_APP_IS_DEBUG == 'true' || idUtenteTmp != undefined && (idUtenteTmp != '99' || (idUtente == 99 && result.data.gratuito))) { //|| if (idUtente !== 99 || (idUtente == 99 && result.data.gratuito)) process.env.REACT_APP_IS_DEBUG == true Se è un questionario gratuito o se l'utente è loggato (99 è l'id che viene dato quando non è loggato)
                                setQuestionario({
                                    nome: result.data.nomeQuestionario,
                                    tipologia: result.data.tipologia,
                                    gratuito: result.data.gratuito,
                                    idVolume: result.data.volumeAssociato.idVolume,
                                    permalink: result.data.permalink,
                                    sottotitolo: result.data.sottotitolo,
                                    sogliaSufficienza: result.data.sogliaSufficienza
                                })
                                let sezioniTmp = result.data.sezione;
                                setPageTitle(result.data.nomeQuestionario);
                                setSezioni(sezioniTmp);
                                if (result.data.descrizionePubblica !== "")
                                    setDescrizione(result.data.descrizionePubblica);
                                else {
                                    setDescrizione("Completa il simulatore " + result.data.nomeQuestionario);
                                }

                                if (result?.data?.introduzione) {
                                    setDescrizioneQuestionario(result?.data?.introduzione ?? "");
                                }

                                let tmpSezioniImpostazioni = Array(sezioniTmp.length)
                                let quizDisponibiliTmp = 0;
                                let quizSequenzialiTotali = 0;
                                for (let i = 0; i < tmpSezioniImpostazioni.length; i++) {
                                    //Appena creo l'array al numero di quiz selezionati assegno il valore max
                                    if (sezioniTmp[i].quizDisponibili >= sezioniTmp[i].numQuiz)
                                        quizDisponibiliTmp += parseInt(sezioniTmp[i].numQuiz);
                                    else quizDisponibiliTmp += parseInt(sezioniTmp[i].quizDisponibili);

                                    let quizSelezionatiTmp = parseInt(sezioniTmp[i].numQuiz);

                                    if (quizSelezionatiTmp > parseInt(sezioniTmp[i].quizDisponibili))
                                        quizSelezionatiTmp = parseInt(sezioniTmp[i].quizDisponibili)
                                    tmpSezioniImpostazioni[i] = {
                                        "quizSelezionati": quizSelezionatiTmp,
                                        "quizDisponibili": parseInt(sezioniTmp[i].quizDisponibili),
                                        "idParte": parseInt(sezioniTmp[i].idParte),
                                        "tempMin": sezioniTmp[i].tempMin,
                                        "tempMax": sezioniTmp[i].tempMax,
                                        "offset": 0,
                                        "idVolume": sezioniTmp[i].idVolume,
                                        "puntiCorretta": sezioniTmp[i].puntiCorretta,
                                        "puntiErrata": sezioniTmp[i].puntiErrata,
                                        "puntiOmessa": sezioniTmp[i].puntiOmessa
                                    }

                                    quizSequenzialiTotali += parseInt(sezioniTmp[i].quizDisponibili)
                                }

                                let nQuizTot = 0;
                                for (let i = 0; i < tmpSezioniImpostazioni.length; i++) {
                                    nQuizTot += parseInt(tmpSezioniImpostazioni[i].quizSelezionati)
                                }
                                setNumeroQuiz(nQuizTot)
                                // setNumeroQuiz(quizDisponibiliTmp);
                                setSezioniImpostazioni(tmpSezioniImpostazioni);
                                setQuizTotaliDisponibili(quizDisponibiliTmp);
                                setQuizTotaliSequenziali(quizSequenzialiTotali);
                                setQuizTotaliSequenzialiSelezionati(quizSequenzialiTotali);
                                getQuizSvolti(idQuestionario, tmpSezioniImpostazioni);
                                setIsLoading(false);

                            } else {
                                setNotLoggedDialogOpen(true);
                                navigate("/home"); //Non sei loggato ti riporto alla home;
                                //setTitleTag();
                            }
                        }
                        else {
                            setQuestionarioNotFound(true);
                        }
                    }
                )
            } else if (res.status === 404) {
                setQuestionarioNotFound(true);
            } else if (res.status === 401 || res.status === 403) {
                window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
            } else if (res.status === 500) {
                navigate("/home")
            }
        } catch (error) { }
    }

    async function getQuizSvolti(idQuestionario, sezioni) {
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "idQuestionario": idQuestionario
            })
        };

        fetch(process.env.REACT_APP_FETCH_URL + 'questionario/quizSvolti', requestOptions)
            .then(response => response.json())
            .then(res => {
                if (res.success === true) {
                    let newSezioni = [];
                    if (res.data.length > 0) {
                        for (let sezione of sezioni) {
                            let percentuale = -1;
                            for (let i = 0; i < res.data.length; i++) {
                                if (res.data[i].volumeparte_id == sezione.idParte) {
                                    let percentualeTmp = (res.data[i].count / 675) * 100;
                                    percentualeTmp = percentualeTmp.toFixed(2)
                                    percentuale = percentualeTmp;
                                    //return
                                }
                            }
                            newSezioni.push(percentuale);
                        }
                        setPercentualiQuiz(newSezioni);
                        //setSezioni(newSezioni);
                    }
                }
                if (res.status === 403) {
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                }
            });
    }

    function changeTipoTest(newTipoTest) {
        if (newTipoTest != tipoTest) {
            // if (newTipoTest === 'Seq') {
            //     setNumeroQuiz(quizTotaliSequenziali);
            // } else {
            changeNumeroQuizTot(sezioniImpostazioni, newTipoTest);
            //}
        }
        setTipoTest(newTipoTest);
    }
    function changeNumeroQuiz(nuovoNumeroQuiz, index, quizDiPartenza) {
        let sezioniTmp = sezioniImpostazioni
        if (nuovoNumeroQuiz <= sezioniTmp[index].quizDisponibili)
            sezioniTmp[index].quizSelezionati = nuovoNumeroQuiz;
        else sezioniTmp[index].quizSelezionati = sezioniTmp[index].quizDisponibili;

        if (tipoTest == 'Seq') {
            sezioniTmp[index].offset = quizDiPartenza;
        }
        // else {
        //     if (sezioniTmp[index].hasOwnProperty('offset'))
        //         delete sezioniTmp[index].offset;
        // }
        setSezioniImpostazioni(sezioniTmp);
        changeNumeroQuizTot(sezioniTmp, tipoTest);
    }
    function changeNumeroQuizTot(sezioniImpostazioniEse, newTipo) {
        let nQuizTot = 0;
        //tipoTest ==="Sim" vuol dire che la prima volta che apro il questionario mi ritrovo la simulazione ma cambio imposto 
        //Il numero di quiz all'esercitazione
        if (newTipo === "Sim") {
            for (let i = 0; i < sezioni.length; i++) {
                nQuizTot += parseInt(sezioni[i].numQuiz)
            }
        }
        if (newTipo === "Ese" || newTipo === "Seq") {
            for (let i = 0; i < sezioniImpostazioniEse.length; i++) {
                nQuizTot += parseInt(sezioniImpostazioniEse[i].quizSelezionati)
            }
        }
        setNumeroQuiz(nQuizTot);
        setQuestionarioIsLoaded(true);

    }
    function openRiepilogoDialog() {
        let tmpOpen = !riepilogoDialogOpen;
        setRiepilogoDialogOpen(tmpOpen);
    }

    function goToElencoVolumi() {
        navigate("/home", { state: { isbn: isbnVolume } }) //VolumeSelezionato a false non mostra la dialog
    }
    function goToQuiz() {
        if (questionario.gratuito || (tipoTest !== "Sim" && numeroQuiz > 0) || tipoTest === "Sim") {
            let prettyLink;
            let state = {
                idQuestionario: questionarioSelezionatoID,
                idVolume: questionario.idVolume,
                idUtente: idUtente,
                type: tipoTest,
                parti: sezioniImpostazioni,
                gratuito: questionario.gratuito,
                descrizione: questionario.descrizionePubblica,
                prettyLink: questionario.permalink != null ? questionario.permalink : ''
            }

            if (tipoTest !== "Seq")
                state.riprovaSbagliati = soloQuizSbagliati

            if (!questionario.gratuito) {
                state.quizSequenzialiSelezionati = quizTotaliSequenzialiSelezionati;
                state.questionario = questionario;
                state.numeroQuiz = numeroQuiz;
            }

            if (questionario.permalink != null) {
                prettyLink = questionario.permalink
                prettyLink = prettyLink.replace(/\s+/g, '-');
                prettyLink = prettyLink.replace(/\//g, '');

            } else {
                prettyLink = questionario.nome.toLowerCase();
                prettyLink = prettyLink.replace(/\s+/g, '-')
                prettyLink = prettyLink.replace(/\//g, '');
            }
            navigate("/quiz/" + prettyLink, { state: state })

        }
    }

    function changeCheckSoloQuizSbagliati() {
        setSoloQuizSbagliati(prev => !prev)
    }

    return (
        <Fragment>
            <Helmet>
                <title> {pageTitle}</title>
                <meta name="description" content={descrizione} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={descrizione} />
                <meta property="og:url" content={permalinkUrl} />
            </Helmet>
            {!isLoading ? (
                <Container sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h2" sx={{ mt: { xs: 2, md: 5 }, textAlign: { xs: 'center', md: 'start' } }}>
                        {questionario.nome}
                    </Typography>
                    {(descrizioneQuestionario != undefined && descrizioneQuestionario != null) && (
                        <Typography variant="subtitle1" sx={{ mt: { xs: 1, md: 1 } }}>
                            {descrizioneQuestionario ?? ""}
                        </Typography>
                    )}
                    {questionario.sottotitolo != undefined && (
                        <Typography variant="h5" sx={{ textAlign: { xs: 'center', md: 'start' } }}>
                            {questionario.sottotitolo}
                        </Typography>
                    )}

                    {/*TIPO QUESTIONARIO */}
                    {!questionario.gratuito && (
                        <Paper sx={{ mt: 4, p: 4 }} elevation={5}>
                            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, mr: 2 }}>
                                    <Tooltip title="Simula la prova di concorso" arrow>
                                        <Button sx={{ height: 'fit-content', width: { xs: '100%', md: 'fit-content' } }} onClick={() => { changeTipoTest('Sim') }} variant={tipoTest === 'Sim' ? 'contained' : 'text'}>Simulazione</Button>
                                    </Tooltip>
                                    <Tooltip title="Esercitati selezionando il numero di quesiti per ogni materia" arrow>
                                        <Button disabled={numeroQuiz === 0} sx={{ width: { xs: '100%', md: 'fit-content' }, ml: 1, height: 'fit-content', my: { xs: 2, md: 0 } }} onClick={() => { changeTipoTest('Ese') }} variant={tipoTest === 'Ese' ? 'contained' : 'text'}>Esercitazione</Button>
                                    </Tooltip>
                                    <Tooltip title="Tutti i quesiti in ordine" arrow>
                                        <Button disabled={numeroQuiz === 0} sx={{ width: { xs: '100%', md: 'fit-content' }, ml: 1, height: 'fit-content' }} onClick={() => { changeTipoTest('Seq') }} variant={tipoTest === 'Seq' ? 'contained' : 'text'}>In sequenza</Button>
                                    </Tooltip>
                                </Grid>
                                <Divider sx={{ mt: 2 }} orientation="vertical" flexItem />

                                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Typography variant="body">Numero di quiz: <b>{numeroQuiz}</b></Typography>
                                    {tipoTest !== "Seq" && (
                                        <FormControlLabel sx={{ mt: { xs: 2, md: 1 }, mx: 0 }} control={<Checkbox onChange={changeCheckSoloQuizSbagliati} checked={soloQuizSbagliati} />} label=
                                            {
                                                <Fragment>
                                                    <Typography>Dai precedenza ai quiz più sbagliati in precedenza</Typography>
                                                </Fragment>
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {/*SEZIONI */}
                    {/* <Box sx={{ display: tipoTest != 'Seq' ? 'block' : 'none' }}> */}
                    <Box>
                        {(numeroQuiz === 0 && !isLoading) && (
                            <Typography variant="h5" sx={{ textAlign: 'center', my: 5, color: '#003370' }}>Al momento non è possibile avviare il simulatore, si prega di riprovare tra qualche minuto.</Typography>
                        )}
                        {(tipoTest == 'Sim' && numeroQuiz > 0) && (
                            <Fragment>
                                <Typography variant="h5" sx={{ mt: 5, color: '#003370' }}>
                                    Materie della prova di concorso
                                </Typography>
                                <Box sx={{ mt: 3 }}></Box>
                            </Fragment>
                        )}
                        {(tipoTest == 'Ese' && numeroQuiz > 0) && (
                            <Fragment>
                                <Typography variant="h5" sx={{ mt: 5, color: '#003370' }}>
                                    Crea il tuo simulatore:
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3, color: '#003370' }}>
                                    Seleziona il numero di quesiti per ogni materia. Se vuoi esercitarti su una sola materia puoi azzerare tutti i quiz delle altre materie. I quiz saranno somministrati in maniera casuale
                                </Typography>
                            </Fragment>
                        )}
                        {(tipoTest == 'Seq' && numeroQuiz > 0) && (
                            <Fragment>
                                <Typography variant="h5" sx={{ mt: 5, color: '#003370' }}>
                                    Quesiti in sequenza per materia:
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3, color: '#003370' }}>
                                    Tutti i quesiti sono in ordine di inserimento. Esercitati con tutti i quesiti in successione. Se interrompi la sessione del
                                    test vedrai la percentuale di completamento. Puoi interrompere e riavviare il test a
                                    distanza di giorni.
                                </Typography>
                            </Fragment>
                        )}
                        <Grid container spacing={3}>
                            {sezioni.map((sezione, index) =>
                                sezione.quizDisponibili > 0 && (
                                    <Grid item xs={12} md={4} sx={{ width: '100%' }} key={index}>
                                        <Sezione tipoTest={tipoTest} sezione={sezione} indexSezione={index} percentualiQuiz={percentualiQuiz}
                                            changeNumeroQuiz={changeNumeroQuiz} />
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>

                    {/*BOTTONI */}
                    {!questionario.gratuito ? (
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'space-between', mt: 5 }}>
                            <Box>
                                <Button variant="contained" onClick={goToElencoVolumi} sx={{ width: { xs: '100%', md: 'fit-content' } }} startIcon={<ArrowBackIcon />}>Ritorna ai simulatori sbloccati</Button>
                                <Button variant="outlined" onClick={openRiepilogoDialog} sx={{ mt: { xs: 1, md: 0 }, ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}>
                                    Mostra il riepilogo dei test svolti
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', width: { xs: '100%', md: 'fit-content' } }}>
                                {(tipoTest === 'Ese' && numeroQuiz == 0) && (
                                    <Typography sx={{ color: 'red', mr: { xs: 0, md: 2 }, order: { xs: 2, md: 0 } }}>Prima di proseguire è necessario selezionare almeno un quiz.</Typography>
                                )}
                                <Button onClick={goToQuiz} sx={{ width: { xs: '100%', md: 'fit-content' }, my: { xs: 1, md: 0 } }} disabled={numeroQuiz == 0} variant="contained" color='success'>
                                    {tipoTest === 'Ese' && (<span>Avvia esercitazione</span>)}
                                    {(tipoTest === 'Sim' || tipoTest === 'Seq') && (<span>Avvia simulazione</span>)}
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Button disabled={numeroQuiz == 0} onClick={goToQuiz} sx={{ width: { xs: '100%', md: 'fit-content' }, my: { xs: 1, md: 0 } }} variant="contained" color='success'>
                                Avvia simulatore
                            </Button>
                        </Box>
                    )}

                    {/*FOOTER*/}
                    <Divider sx={{ mt: 5, mb: 2 }} />
                    <Footer />


                    {/*DIALOG RIEPILOGO*/}
                    <Dialog fullWidth maxWidth={'xl'} open={riepilogoDialogOpen} onClose={openRiepilogoDialog} >
                        <DialogContent>
                            <RiepilogoQuizSvolti idUtente={idUtente} questionarioID={questionarioSelezionatoID} onClose={openRiepilogoDialog} />
                        </DialogContent>
                    </Dialog>

                    {/*DIALOG NON LOGGATO */}
                    <Dialog open={notLoggedDialogOpen}>
                        <DialogContent>
                            <CircularProgress
                                variant="indeterminate"
                                disableShrink
                                sx={{
                                    animationDuration: '550ms',
                                    mt: 2,
                                    left: 0,
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                    },
                                }}
                                size={50}
                                thickness={4} />
                        </DialogContent>
                    </Dialog>

                </Container>
            ) : (
                <Box sx={{ minHeight: '100vh', width: '100%', display: 'flex', alignItems: "center", justifyContent: 'center' }}>

                    <CircularProgress
                        variant="indeterminate"
                        color="secondary"
                        disableShrink
                        sx={{
                            animationDuration: '550ms',
                            mb: 1,
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={40}
                        thickness={4} />
                </Box>
            )}
        </Fragment>
    )
}

